import firebase from "firebase";

var firebaseConfig = {
    apiKey: "AIzaSyB8E1zuSQe5NPVnUJC37kmR9khrZwDT9xE",
    authDomain: "adn12project-site.firebaseapp.com",
    databaseURL:"https://adn12project-site-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "adn12project-site",
    storageBucket: "adn12project-site.appspot.com",
    messagingSenderId: "106629311846",
    appId: "1:106629311846:web:0ff1984426a34eefabaca7",
    measurementId: "G-JS3NG459LZ"
  };

firebase.initializeApp(firebaseConfig);
firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    export default firebase;    