<template>
  <div class="row">
    <div class="content">
      <span class="item">جميع الحقوق محفوظة - {{myDate}}</span>
      <router-link to="/" class="item link"><img src="../assets/small_logo.png"></router-link>
      <div @click="goToWork" class="item link"> أعمالي - My work</div>
      <div @click="goToServices" class="item link">الخدمات - Services</div>
      <router-link to="/terms" class="item link">الشروط - Terms</router-link>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myDate: new Date().getFullYear(),
    };
  },
  emits:['go-to-works','go-to-services'],
  methods:{
    goToWork(){
      this.$emit("go-to-works");
    },
    goToServices(){
      this.$emit("go-to-services");
    }
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  background-color: black;
  .content {
    direction: rtl;
    //text-align: center;
    margin: 0 auto 0 auto;

    max-width: 1120px;
    display: flex;
    flex-direction: row;
    
    .item {
      padding: 10px;
      color: white;
      font-family: "Cairo";
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      width: 100%;
      padding:5px 0 5px 0;
      
    }
    .link{
      cursor: pointer;
      text-decoration: none;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .item{
font-size:18px;
      }
      
    }
    img {
      width: 40px;
    }
  }
}
</style>
