<template>
  <div class="logo_container">
    <router-link to="/"><img id="logo" alt="Adn12 Logo" src="../assets/logo.png" /></router-link>
  </div>
</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo_container{
  background:#F4F4F4;
   padding:0 0 0 0;
  max-height:221px;
  // border-bottom: 1px solid #7B7B7B;
  
}
#logo{
 
  width:100%;
  max-width:600px;
  padding:0 0 0 0;
  margin:0  0 0 0;
}
</style>
