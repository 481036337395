<template>
  <div class="row">
    <div class="content">
      <h1>بعض أعمالي - Some of my work<hr/></h1>
      
      <img v-for="(image,index) in webImageList" :key="index" :src="image"/>
      <!-- <img v-for="(image,index) in webImageList" :key="index" src="../assets/logo.png"/> -->
    </div>
  </div>
</template>

<script>
//function requireAll(r) { r.keys().forEach(r); }
import firebase from "@/firebase.js";
export default {
  name: "MyWorks",
  data() {
    return {
      webImageList: [],
    };
  },
  components: {},
  // mounted() {
  //   //requireAll(require.context('@/assets/works/app', true, /\.png$/));
  //   var illustrations = require.context("@/assets/web", true, /\.png$/);
  //   //console.log(requireAll(require.context('@/assets/works/apps', true, /\.png$/)));
  //   var myArray = [];
  //   illustrations.keys().map(i=>{
  //     myArray.push( i.substring(2));
  //   })
  //   console.log(myArray);
  //  this.webImageList =myArray;

  // },
  mounted() {
    firebase
      .storage()
      .ref("web")
      .listAll()
      .then((x) => {
        x.items.forEach( itemRef =>{
          itemRef.getDownloadURL().then(xx=>{
            this.webImageList.push(xx)
          })
          
        });
         firebase
      .storage()
      .ref("apps")
      .listAll()
      .then((x) => {
        x.items.forEach( itemRef =>{
          itemRef.getDownloadURL().then(xx=>{
            this.webImageList.push(xx)
          })
        });
        firebase
      .storage()
      .ref("fun")
      .listAll()
      .then((x) => {
        x.items.forEach( itemRef =>{
          itemRef.getDownloadURL().then(xx=>{
            this.webImageList.push(xx)
          })
        });
      });
      });
      });
     
       
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  // border-bottom: 3px solid #1d9ff1;
  // border-top: 2px solid white;
  background-color: #04151f;
  h1{
    direction: rtl;
    color:white;
    hr{
      border:0;
      border-top:2px solid white;
      width:50%;
    }
    
  }
}
img {
  max-width:21%;
 -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  border: 2px solid white;
  
  margin: 5px;
   padding:0px;
  @media (max-width: 599px) {
     max-width:40%;
  }
}
.content {
  padding-top:10px;
  p {
    margin: 0 0 0 0;
  }

  //.small-container{
  max-width: 1120px;
  margin: 0 auto 0 auto;
  .text-block {
    background: white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 90%;
    margin: 10px auto 0 auto;
  }
  //}
  * {
    font-family: "Cairo";
    font-weight: 300;
    color: #333333;
    // padding-top: 10px;
    // padding-right: 10px;
    // padding-left: 10px;
  }
  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
  }
  h1 {
    margin: 0 0 0 0;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .left {
    text-align: left;
    direction: ltr;
  }
  .right {
    text-align: right;
    direction: rtl;
  }
  .small_text {
    font-size: 17px;
    text-align: justify;
  }
}
@media (min-width: 769px) {
  h1 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 10px;
  }
}
a.my_work_button_div,
.my_work_button_div {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #000000;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Cairo;
  font-weight: 300;
  color: #000000;
  text-align: center;
  font-size: 20px;
  transition: all 0.2s;
}

a.my_work_button_div:hover,
.my_work_button_div:hover {
  color: white;
  background-color: #000000;
}

</style>
