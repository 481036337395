<template>
 
  <router-view/>
</template>
<script>
//import Header from '@/components/Header.vue';

export default {
  name: 'Header',
  components: {
    
   // Header
  }
}
</script>
<style lang="scss">
body{
  margin:0 0 0 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
