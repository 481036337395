<template>
  <div ref="my-top"></div>
  <Header />
  <hr class="logo-whoami" />
  <div class="container">
    <!-- <div class="small-container"> -->
      
      <Contact v-if="$route.query.path == 'contact'" />
    <WhoAmI @go-to-works="goToWorks" />
    <div ref="my-services" class="whoami-services"></div>
    <!-- </div> -->
    <Services @go-to-contact="goToContact" />
    <div class="services-difference"></div>
    <Difference />
    
    
    <div ref="my-works" class="difference-myworks"></div>
    <MyWorks />
    <div  ref="my-contact" class="myworks-contact"></div>
    <Contact />
    <div  ref="my-footer" class="contact-footer"></div>
    <Footer  @go-to-services="goToServices" @go-to-works="goToWorks" />
  </div>

</template>

<script>
import Header from "@/components/Header.vue";
import WhoAmI from "@/components/WhoAmI.vue";
import Services from "@/components/Services.vue";
import Difference from "@/components/Difference.vue";
import MyWorks from "@/components/MyWorks.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    WhoAmI,
    Header,
    Services,
    Difference,
    MyWorks,
    Contact,
    Footer,
  },
  mounted(){
    console.log(this.$route.query.path);
//     if(this.$route.query.path=="contact"){
//       //this.goToWorks();
//       setTimeout(() => {
//   this.goToContact();
//  })
      
//     }
  },
  methods: {
    goToWorks() {
      var el = this.$refs["my-works"];
      //el.scrollIntoView({behaviour:'smooth'});
      console.log(el);
      el.scrollIntoView({ behavior: "smooth" });
    },
    goToContact() {
      var el = this.$refs["my-contact"];
      //el.scrollIntoView({behaviour:'smooth'});
      console.log(el);
      el.scrollIntoView({ behavior: "smooth" });
    },
    
    goToServices() {
      var el = this.$refs["my-services"];
      //el.scrollIntoView({behaviour:'smooth'});
      console.log(el);
      el.scrollIntoView({ behavior: "smooth" });
    },
    
  },
};
</script>

<style lang="scss" scoped>
$my-blue: #1d9ff1;
$my-dark-blue: #1879b4;
$my-red: #f11d1d;
$my-dark-red: #ffffff;

hr {
  margin: 0 0 0 0;
  border: 0;
}
.logo-whoami {
  border-top: 1px solid #7b7b7b;
  border-bottom: 2px solid #d8d8d8;
}
.whoami-services {
  border-top: 2px solid #c01919;
  background-color: $my-red;
  height: 10px;
  border-bottom: 1px solid $my-dark-red;
}
.services-difference {
  border-top: 1px solid white;
  background-color: $my-blue;
  height: 10px;
  border-bottom: 2px solid $my-dark-blue;
}
.difference-myworks {
  border-top: 2px solid $my-dark-blue;
  background-color: $my-blue;
  height: 10px;
  border-bottom: 1px solid white;
}
.myworks-contact {
  border-top: 1px solid white;
  background-color: $my-red;
  height: 10px;
  border-bottom: 1px solid white;
}
.contact-footer {
  border-top: 2px solid rgb(122, 122, 122);
  border-bottom: 1 solid white;
}
</style>
