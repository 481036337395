<template>
  <div class="row">
    <h1 class="ar">ما نوع الخدمات التي تقدمها؟</h1>
    <h1 class="en">What type of services do you provide?</h1>
    <div class="content">

      <div class="flex-item">
       
        <img src="../assets/app.png" />
         <h2>تصميم تطبيقات</h2>
        <h2>App Development</h2>
      </div>
    <hr class="service-divider"/>
      <div class="flex-item">
       
        <img src="../assets/custom.png" />
         <h2>برمجة خاصة</h2>
        <h2>Custom Programming</h2>
      </div>
      <hr class="service-divider"/>
      <div class="flex-item">
       
        <img src="../assets/web.png" />
         <h2>تصميم مواقع</h2>
        <h2>Web Design</h2>
      </div>
 
    </div>
   <div class="content-no-flex">
     <hr/>

     <p class="ar">مواقع تجارية، لمنتج واحد او ألف، برامج ايفون او أندرويد، مواقع شخصية وغيره.</p>
      <p class="en">E-Commerce for one product? or a thousand, iPhone or Android apps, personal websites or whatever.</p>
     </div>
     <div> <a @click="goToContact" class="my_work_button_div">Free consultation - استشارة مجانية</a></div>
  </div>
</template>

<script>
export default {
  name: "Services",
  emits:['go-to-contact'],
  methods:{
    goToContact(){
      this.$emit("go-to-contact");
    }
  }
  
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  // border-top: 2px solid #0a67a1;
  // border-bottom: 2px solid #a10a0a;
  .service-divider{
    width:80%;
    margin-top:20px;
     border:0;
      border-top:1px solid rgb(110, 110, 110);
      @media (min-width: 769px) {
     
      display:none;
    }

    @media (max-width: 768px) {
     display:block;
    }
  }
  img {
    width: 70px;
    //border: 1px solid #8a8f92;
    padding: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  h1 {
    color: white;
  }
  .content-no-flex{
    max-width: 1120px;

    margin: 0 auto 0 auto;
    hr{
      margin-top:20px;
      width:80%;
      border:0;
      border-top:1px solid rgb(110, 110, 110);
    }
    p{
      color:white;
      
      font-size: 20px;
    

    
    }
    .ar{
      direction: rtl;
      text-align: center;
    }
    .en{
      direction: ltr;
      text-align: center;
    }
  }
  .content {
    max-width: 1120px;

    margin: 0 auto 0 auto;
    display: flex;
    @media (min-width: 769px) {
      flex-direction: row;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
    .flex-item {
      width: 100%;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
    }
    h2 {
      color: white;
      margin:0 0 0 0;
    }
  }
  background-color: #31393c;
  * {
    font-family: "Cairo";
    font-weight: 300;
    color: #333333;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
  }
  h1 {
    margin: 0 0 0 0;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .left {
    text-align: left;
    direction: ltr;
  }
  .right {
    text-align: right;
    direction: rtl;
  }
  .small_text {
    font-size: 17px;
    text-align: justify;
  }
}
@media (min-width: 769px) {
  h1 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 10px;
  }
}
a.my_work_button_div,
.my_work_button_div {
 cursor: pointer;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid rgb(255, 255, 255);
  margin: 0 0.3em 0.3em 0;
  border-radius: 5px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Cairo;
  font-weight: 300;
   color: black;
  text-align: center;
  font-size: 20px;
  transition: all 0.2s;
   background-color: white;
//    @media (min-width: 769px) {
 
    margin-bottom:30px;
  
// }

@media (max-width: 768px) {
  
   width:100%;
  
}
}

a.my_work_button_div:hover,
.my_work_button_div:hover {
  color: white;
 background-color: #000000;
}
</style>
