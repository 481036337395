<template>
  <Header />
  <div class="container">
    <div class="row">
      <div class=" content ">

        <h1 class="ar">الشروط والأحكام</h1>
        <h1 class="en">Terms and Conditions</h1>

        <div class="text-block terms">
          <p class="ar right important ">هذه الشروط والأحكام تطبق على جميع الخدمات المقدمة من Adn12Project.com للعميل.</p>
          <p class="ar right important">الطرف الأول : Adn12Project.com / الطرف الثاني: العميل</p>
          <p class="ar right important">Adn12Project.com هو موقع أو مجموعة عملها تصميم و برمجة و صيانة وإدارة المواقع والتطبيقات وغيرها من البرمجيات، ويحق لـAdn12Project.com رفض أي طلب بدون إبداء الأسباب</p>
          <hr />
          <h1 class="ar right" dir="rtl">1.0 الطلبات:</h1>
          <p class="ar right" dir="rtl">1.1 لا يعتبر إي طلب مقبولا عند Adn12Project.com إلا بعد أن نبدأ بالعمل على البرنامج، تحدد Adn12Project.com تاريخ البدء بالعمل، وسوف يتم إعلامك بالتاريخ بواسطة الهاتف أو البريد الإلكتروني، وسوف يعتبر هذا التاريخ هو تاريخ بدأ العمل على الطلب
            وعندها، وفقط عندها يعتبر الطلب مقبولا عند Adn12Project.com</p>
          <p class="ar right" dir="rtl">1.2 يجب أن تكون أكبر من 21 سنة عند تقديمك لطلب شراء أي خدمة من Adn12Project.com</p>
          <p class="ar right" dir="rtl">1.3 جميع الطلبات سيتم مراجعتها لرؤية إن كانت مطابقة لشروط Adn12Project.com وفي حال عدم مطابقتها، لـ Adn12Project.com كامل الحق برفض الطلب، بدون إعطاء أي سبب.</p>
          <p class="ar right" dir="rtl">1.4 Adn12Project.com لا تحمل أي مسؤولية تجاه تقديم أي خدمة لم يتم ذكرها والاتفاق المكتوب عليها في الطلب.</p>
          <p class="ar right" dir="rtl">1.5 يجب عليك تقديم جميع المعلومات بدقة التي ترى Adn12Project.com أنها ضرورية لإكمال العمل في برنامجك.</p>
          <p class="ar right" dir="rtl">1.6 أي تعليمات لها علاقة بالطلب مقدمة لـAdn12Project.com من تجاهك، يجب أن تكون كاملة، واضحة، ومعقولة، وAdn12Project.com ليست مسؤولة عن أي خطأ يحصل بسبب عدم التزامك بهذا البند ويحق لـAdn12Project.com طلب أي تكاليف إضافية بسبب خطأ منك في توضيح تعليماتك.</p>
          <p class="ar right" dir="rtl">1.7 الخدمات المقدمة لك مذكورة في الطلب، وأنت تتحمل المسؤولية المطلقة في أن تتأكد أن هذه الاتفاقية والخدمات التي نقدمها هي الملائمة لرغباتك، لإمكانياتك، لخبراتك ولقدراتك ولا يحق لك إلغاء الطلب بعد أن يتم القبول به (كما تم ذكره
            في البند 1.1 من هذه الاتفاقية) بناء على عدم قدرتك على استعمال هذه الخدمات.</p>
          <p class="ar right" dir="rtl">1.8 لا تقوم Adn12Project.com بتقديم أي خدمة لها أي علاقة في المواضيع التالية (وغيرها مما لم يذكر) :</p>
          <div class="ar right square" dir="rtl">التبغ ومشتقاته، من السجائر وغيرها. الكحول بجميع أنواعها، المشروبات، العطور وغيرها. الأدوية بكل أنواعها. أي أغراض غير قانونية، سواء أكانت برامج، ملفات، معلومات، أصوات، فيديو، وغيرها مما يخالف قوانين حقوق الطبع والفكر، أو غيرها
            من القوانين الدولية والمحلية. الموسيقا والأفلام. أي مادة مخالفة للشريعة الإسلامية (بما تراه Adn12Project.com). السياسة. الأسلحة. القمار. وغيرها مما لم يتم ذكره.</div>
          <p class="ar right" dir="rtl">1.9 Adn12Project.com ليست ملزمة بأي تغيير بالطلب بعد قبوله (كما هو مبين في بند 1.1) ولـAdn12Project.com كامل الحق برفض أي تغيير يطرأ على الطلب بعد القبول به (كما هو مبين في بند 1.1)</p>
          <hr />
          <h1 class="ar right" dir="rtl">2.0 الدفع:</h1>
          <p class="ar right" dir="rtl">2.1 يجب عليك دفع مقدم بقيمة لا تقل عن خمسين بالمئة (50%) من التكاليف الكلية للطلب قبل بدأ Adn12Project.com بالعمل، ودفع باقي التكاليف عند إكمال Adn12Project.com للعمل وتسليمه لك بالموعد الذي تحدده Adn12Project.com</p>
          <p class="ar right" dir="rtl">2.2 لـAdn12Project.com كامل الحق بإيقاف وتأخير تسليم الطلب إذا تأخر دفع التكاليف المتفق عليها.</p>
          <p class="ar right" dir="rtl">2.3 التزام Adn12Project.com بتقديم الخدمات لك مشروط بدفعك للتكاليف، جميع الخدمات مرخصة باسمك طالما تقوم بدفع التكاليف، وفي حال فشلك في دفع التكاليف و / أو قمت بإلغاء الطلب، تلغى جميع حقوقك باستخدام هذه الخدمات والمعلومات، التصاميم،
            البرمجة، وكل شيء ما عدا، وفقط ما عدا المعلومات والمحتوى الذي قمت أنت بنفسك بإعطائه لـAdn12Project.com مثل صور شخصية ، معلومات مكتوبة، ملفات صوتية أو ملفات مرئية.</p>
          <p class="ar right" dir="rtl">2.4 إذا رأيت أن هناك أي خطأ في فاتورتك، عليك أن تراسلنا بالكتابة بالبريد الإلكتروني المبين.</p>
          <p class="ar right" dir="rtl">2.5 يجب عليك دفع التكاليف التي تحتاجها Adn12Project.com لتقديم بعض الخدمات الخاصة التي يتم الاتفاق عليها في الطلب، على سبيل المثال (وليس الحصر) رخص بعض البرامج مثل المنتديات والمجلات الإلكترونية.</p>
          <hr />
          <h1 class="ar right" dir="rtl">3.0 الإلغاء:</h1>
          <p class="ar right" dir="rtl">3.1 يحق لAdn12Project.com إلغاء أي طلب في أي وقت:</p>
          <p class="ar right indent" dir="rtl">3.1.1 في حالة مخالفتك لأي شرط من شروط الاتفاقية. وفي هذه الحالة لا يحق لك المطالبة بأي من التكاليف التي قمت بدفعها لـAdn12Project.com</p>
          <p class="ar right indent" dir="rtl">3.1.2 بدون أن تذكر Adn12Project.com أي سبب، وفي هذه الحالة يحق لك المطالبة بالتكاليف التي قمت بدفعها.</p>
          <p class="ar right" dir="rtl">3.2 يحق لك إلغاء الطلب في أي وقت:</p>
          <p class="ar right indent" dir="rtl">3.2.1 في حالة إلغائك للطلب قبل مرور يومين من تاريخ البدا بالعمل (كما هو مبين في بند 1.1) ، يحق لك المطالبة بالتكاليف التي قمت بدفعها لـAdn12Project.com</p>
          <p class="ar right indent" dir="rtl">3.2.2 في حالة إلغائك للطلب بعد مرور يومين من تاريخ البدا بالعمل (كما هو مبين في بند 1.1) ، لا يحق لك المطالبة بالتكاليف التي قمت بدفعها لـAdn12Project.com</p>
          <p class="ar right" dir="rtl">3.3 أي إعلام عن الإلغاء يجب أن يكون بالكتابة الصريحة، بالبريد الإلكتروني المبين.</p>
          <p class="ar right" dir="rtl">3.4 Adn12Project.com غير مسؤولة نهائيا عن أي ضرر ينتج عن إلغاء أي طلب.</p>
          <p class="ar right" dir="rtl">3.5 بعد استلام Adn12Project.com لطلب الإلغاء، قد تستغرق عملية الإلغاء 5 أيام فأكثر، وAdn12Project.com غير مسؤولة عن أي ضرر ينشأ بسبب هذه الفترة.</p>
          <hr />
          <h1 class="ar right" dir="rtl">4.0 المسؤولية:</h1>
          <p class="ar right" dir="rtl">4.1 Adn12Project.com غير مسؤولة عن أي برنامج بأي شكل من الأشكال بعد تسليمه للعميل، وهي غير مسؤولة عن أي محتوى في البرنامج بعد تسليمه للعميل.</p>
          <p class="ar right" dir="rtl">4.2 عند تسليم البرنامج، يعطى العميل جميع المعلومات الخاصة بالبرنامج، أسم المستخدم، كلمة السر، البريد الإلكتروني وغيره من المعلومات، فـAdn12Project.com لا تملك أي مسؤولية أو سلطة على برنامج العميل بعد تسليمه.</p>
          <p class="ar right" dir="rtl">4.3 جميع الرسائل والتعليمات التي ترسلها Adn12Project.com إلى العميل، وتستقبلها من العميل تتم عن طريق البريد الإلكتروني، وAdn12Project.com غير مسؤولة عن أي رسالة تتأخر أو لا تصل بسبب أي عطل أو مشكلة في شركة البريد الإلكتروني التي
            يستخدمها العميل.</p>
          <p class="ar right" dir="rtl">4.4 Adn12Project.com غير ملزمة بتقديم أي خدمة أو مساعدة أو غيرها مما له علاقة في الطلب بواسطة الهاتف، وهذا لأن جميع المعلومات في قواعد البيانات، لا يتم الدخول عليها إلا من خلال المعلومات المرسلة لنا بواسطة البريد الإلكتروني.
            لذا، فأي مساعدة، سؤال، تعديل في طلب، إلغاء وغيره من الخدمات التي تقدمها Adn12Project.com يجب أن يكون بواسطة البريد الإلكتروني.</p>
          <p class="ar right" dir="rtl">4.5 Adn12Project.com قد تستغرق فترة تزيد عن ثلاثة (3) أيام للرد على أي بريد إلكتروني.</p>
          <p class="ar right" dir="rtl">4.6 لا تتحمل Adn12Project.com أي مسؤولية تجاه أي خطأ ناتج عن سبب خارج عن إرادتها، على سبيل المثال (وليس الحصر) أي كارثة طبيعية، مشكلة في خطوط الإنترنت، وغيرها.</p>
          <p class="ar right" dir="rtl">4.7 خلال فترة العمل، وطالما يقوم العميل بدفع التكاليف، تعطيك Adn12Project.com حقوقا غير حصرية لكل ما يتم تقديمه من خدمات للبرنامجك بما يتوافق مع شروط هذه الاتفاقية.</p>
          <p class="ar right" dir="rtl">4.8 إذا لم تقدم Adn12Project.com الترخيص اللازم لاستخدام برنامج ما، فأنت توافق على الحصول على هذا الترخيص.</p>
          <hr />
          <h1 class="ar right" dir="rtl">5.0 الكفالة:</h1>
          <p class="ar right" dir="rtl">5.1 تكفل Adn12Project.com أن:</p>
          <p class="ar right indent" dir="rtl">5.1.1 أن تقدم جهدا معقولا لتقديم الخدمة لك بشكل احترافي وعملي.</p>
          <p class="ar right indent" dir="rtl">5.1.2 أن تسترجع أنت (العميل) أي تكاليف قمت بدفعها في بعض الحالات (كما في البنود 3.1.1 - 3.1.2 - 3.2.1 - 3.2.2 )</p>
          <p class="ar right" dir="rtl">5.2 أنت (العميل) تكفل أن:</p>
          <p class="ar right indent" dir="rtl">5.2.1 أنك تملك الخبرة والعلم الضروري لاستخدام الخدمات.</p>
          <p class="ar right indent" dir="rtl">5.2.2 أنك أنت ومستخدمين برنامجك، تفهمون وتقدرون المخاطرة عليك (العميل) وعلى برنامجك من استخدامك للإنترنت.</p>
          <p class="ar right indent" dir="rtl">5.2.3 عندك علم كافي في إدارة، تصميم وتشغيل الوظائف الموجودة و / أو الممثلة في الخدمات للاستفادة من هذه الخدمات.</p>
          <p class="ar right indent" dir="rtl">5.2.4 أنك (العميل) لن تقوم بخرق أي قانون أو لوائح باستخدامك للخدمات.</p>
          <p class="ar right indent" dir="rtl">5.2.5 أنك (العميل) تملك جميع الحقوق الفكرية أو رخصة رسمية لأي معلومة، ملف، أو أي شيء تقدمه لـAdn12Project.com .</p>
          <p class="ar right" dir="rtl">5.3 في غير هذه البنود (5.1 5.2) لا تقدم Adn12Project.com وهي غير مسؤولة عن أي كفالة أخرى</p>

          <div class="square">
            <p class="ar right">
              أنت توافق (لأقصى حدود القانون الساري) أنك لن (تحت أي ظرف مهما كان) تُحمل أي مسؤولية علينا أو أي من موظفينا، عملائنا، شركائنا، على أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو تعويضات عقابية لأي من الأمور التالية (غير حصر على الأمور التالية) : الأضرار
              المتعلقة بالأرباح الضائعة ، وفورات في التكاليف والإيرادات والأعمال التجارية وبيانات أو استخدام ، أو غيرها من خسارة مالية من قبلك أو أي طرف ثالث آخر. أنت توافق على القيود السابق ذكرها سواء في عمل في عقد
              أو ضرر، أو أي نظرية قانونية. وتطبق حتى لو تم إعلامنا بإمكانية حصول هذه الأضرار، لن نكون مسؤولين تجاهك في أي حال على الإجماع, أنت توافق على أن التكاليف التي دفعتها هي فقط التكاليف المدونة في سجلاتنا.</p>
          </div>
          <p class="ar right important" style="color:#e74c3c; margin-top:30px;">عندما تقوم بشراء أي خدمة من Adn12Project.com فأنت توافق على جميع الشروط المذكورة في هذه الإتفاقية وعلى أنك قرأت هذه الإتفاقية بالكامل، على أنك تفهم وتملك القدرة على قراءة اللغة العربية والكتابة العربية</p>
          <hr />
          <p class="en left important" style="color:#e74c3c;margin-bottom:30px;">When you purchase Any service from Adn12Project.com, You agree to all the terms mentioned in this aggreement and that you have read this entire agreement, and that you understand the arabic language and are able to
            read arabic writing

          </p>
        </div>
      </div>
    </div>
  </div>
  <Contact />
  <div class="contact-footer"></div>
  <Footer />
</template>
<script>
import Header from "@/components/Header.vue";

import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Terms",
  components: {
    Header,

    Contact,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.contact-footer {
  border-top: 2px solid rgb(122, 122, 122);
  border-bottom: 1 solid white;
}
.container {
  background-color: #f4f4f4;
  *{
    font-family: "Cairo";
    
    }
}
.content {
  direction: rtl;
  padding:0 10px 0 10px;
  h1 {
    margin: 0 0 0 0;
    font-weight:300;
  }
  max-width: 1120px;

  margin: 0 auto 0 auto;
  .text-block {
    .square{
      border:1px solid black;
       -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
      padding:10px;
    }
    .important{
      text-align:center;
      font-weight:700;
      font-size:14px;
    }
    .indent{
      text-indent: 20px;
    }
    padding:10px;
    font-size:14px;
    text-align: justify;
    background-color: white;
     -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    
  }
}
</style>