<template>
  <div class="row">
    <div class="content">

      <h1 class="ar">مالفرق بينك وبين شركات التصميم؟</h1>
      <h1 class="en">Why are you better than a company?</h1>

      <div class="text-block">
        <p class="ar right small_text">الفرق أني لا أحتاج أن ادفع معاشات لموظفين، ومدراء، وعمال، مما يجعل أسعار خدماتي منخفض بشكل ملحوظ، مقارنة بأسعار الشركات. الفرق ان العقد بيني وبينك واضح ومكتوب بلغة مفهومة مبسطة، وسأشرح لك كل نقطة في العقد لا تجدها واضحة، الفرق أني التزم بمواعيدي وأخبرك من قبل ان نتفق ان كنت لا أستطيع انهاء المشروع او اذا كان خارج قدرتي. الفرق أنك تتعامل مع انسان واحد، تعرفه وتستطيع التواصل معه بأي لحظة. بدون بدالة.</p>
        <hr />
        <p class="en left small_text">The difference is that I don’t need to pay employees, managers or workers, which make the cost of your project significantly lower when compared to development companies. The contract between you and me is simple and clear, written in a way that humans can understand, and I will explain every point you find vague. You can trust that I keep my deadlines, and you will know if a project is out of my area of expertise because I will tell you before we start. The difference is, you deal with one human, a human you know, and can reach at any moment, without a call center.</p>
      </div>
        <div> <router-link to="/terms" class="my_work_button_div">العقد وطريقة العمل - Contract & Our method</router-link></div>
    </div>
  
  </div>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row{
  // border-bottom:3px solid #1D9FF1;
  // border-top:3px solid #f11d1d;
  background-color:#F4F4F4;
  padding-bottom:20px;
}
.content {
  p{
    margin: 0 0 0 0;
  }
  
  //.small-container{
    max-width:1120px;
    margin:0 auto 0 auto;
    .text-block{
      background:white;
      -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width:90%;
    margin:10px auto 0 auto;
    }
  //}
  * {
    font-family: "Cairo";
    font-weight: 300;
    color: #333333;
    padding-top:10px;
    padding-right:10px;
    padding-left:10px;
  }
  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
  }
  h1 {
      margin:0 0 0 0;
    font-size: 30px;
     @media (max-width: 768px) {
            font-size: 20px;
            font-weight:500;
       }
  }
  .left {
    text-align: left;
    direction: ltr;
  }
  .right {
    text-align: right;
    direction: rtl;
  }
  .small_text {
    font-size: 17px;
    text-align: justify;
    
  }
}
@media (min-width: 769px) {
      h1 {
        font-size: 30px;
       
      }
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 10px;
        
      }
    }
   a.my_work_button_div,
.my_work_button_div {
  
 cursor: pointer;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid rgb(255, 255, 255);
  margin: 0 0.3em 0 0;
  margin-top:20px;
  border-radius: 5px;
  box-sizing: border-box;
  text-decoration: none;
  direction: rtl;
  font-family: "Cairo";
  font-weight: 300;
   color: white;
  text-align: center;
  font-size: 20px;
  transition: all 0.2s;
   background-color: #04151F;
//    @media (min-width: 769px) {
 
    
  
// }

@media (max-width: 768px) {
  
   width:100%;
  font-size:16px;
}
}

a.my_work_button_div:hover,
.my_work_button_div:hover {
  color: white;
 background-color: #1D9FF1;
}
</style>
