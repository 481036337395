<template>
  <div class="row">
    <div class="content">

      <h1 class="ar">من انت، وماذا تقدم؟</h1>
      <h1 class="en">Who are you, and what do you offer?</h1>

      <div class="text-block">
        <p class="ar right small_text"> مصمم ومبرمج كويتي أعمل في المجال منذ 15 سنة، اقدم لكم خدمة تصميم المواقع، التطبيقات، المشاريع، بشفافية وصدق بالتعامل. اذا تعاملت معي ستجدني واضحا في كل نقطة، ومصلحتك دائما أمام عيني.</p>
        <hr />
        <p class="en left small_text">A Kuwaiti programmer and designer, been working in the field for over 15 years. I provide website/apps development services, I work with complete transparency, if you work with me, you will find me extremely clear
          about every point, with your convenience in mind.</p>
      </div>
        <div> <a @click="goToWork" class="my_work_button_div">Some of my Work - بعض أعمالي</a></div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "MyWorks",
  emits:['go-to-works'],
  methods:{
    goToWork(){
      this.$emit("go-to-works");
    }
  }
  
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row{
  //border-bottom:3px solid #1D9FF1;
  
}
.content {
  //.small-container{
    max-width:1120px;
    margin:0 auto 20px auto;
    
  //}
  * {
    font-family: "Cairo";
    font-weight: 300;
    color: #333333;
    padding-top:10px;
    padding-right:10px;
    padding-left:10px;
  }
  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
  }
  h1 {
      margin:0 0 0 0;
    font-size: 30px;
     @media (max-width: 768px) {
            font-size: 20px;
            font-weight:500;
       }
  }
  .left {
    text-align: left;
    direction: ltr;
  }
  .right {
    text-align: right;
    direction: rtl;
  }
  .small_text {
    font-size: 17px;
    text-align: justify;
    
  }
}
@media (min-width: 769px) {
      h1 {
        font-size: 30px;
       
      }
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 10px;
        
      }
    }
a.my_work_button_div,
.my_work_button_div {
 cursor: pointer;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid rgb(255, 255, 255);
  margin: 0 0.3em 0.3em 0;
  -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Cairo;
  font-weight: 300;
   color: black;
  text-align: center;
  font-size: 20px;
  transition: all 0.2s;
   background-color: #fdca40;
//    @media (min-width: 769px) {
 
    margin-bottom:30px;
  
// }

@media (max-width: 768px) {
  
   width:100%;
  
}
}

a.my_work_button_div:hover,
.my_work_button_div:hover {
  color: white;
 background-color: #000000;
}
</style>
