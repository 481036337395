  <template>
  <div id="contact-us" class="row">
    <h1>تواصل معنا - Contact us
      <hr />
    </h1>

    <div class="content">

      <div class="flex-item email-container">
        <p>يمكنك مراسلتنا على البريد التالي</p>
        <p>You can contact us via this email</p>
        <a href="mailto: contact@adn12project.com" class="email-button">Contact@adn12project.com</a>
        <p>أو استخدم النموذج وسنقوم بالرد بأسرع وقت</p>
        <p>Or use the form and we will answer ASAP</p>
      </div>

      <div class="flex-item input-container">
        <span class="error" v-if="nameEmpty">الرجاء ادخال الاسم - Name required</span>
        <input type="text" v-model="name" @blur="letsGo" placeholder="Name - الأسم" :class="{'error-input':nameEmpty}" />

        <input type="email" v-model="email" placeholder="Email - البريد" />
        <span class="error" v-if="phoneEmpty">الرجاء ادخال الهاتف - Phone required</span>
        <input type="tel" v-model="myPhone" @blur="letsGo" placeholder="Phone - الهاتف" :class="{'error-input':phoneEmpty}" />

        <input type="tel" v-model="budget" placeholder="Budget - الميزانية" />

        <textarea  v-model="query" placeholder="Query - الاستفسار"></textarea>
        <span class="error" v-if="answerEmpty">الجواب مطلوب - Answer required</span>
        <input type="tel" :class="['important-phone',{'error-input':nameEmpty}]" @blur="letsGo" v-model.number="phone" :placeholder="questionStringEn + ' | ' + questionStringAr" />

        <a @click="letsGo" class="my_work_button_div">Send - ارسل</a>
        <span class="error" v-if="errorGotten">تأكد من الجواب - Check your answer</span>
        <span class="success" v-if="success">تم الإرسال بنجاح!</span>
      </div>

    </div>
    <div class="content-no-flex">
      <hr />

      <p class="ar">معلوماتك بأمان، لن نشارك بها أحد أبدا</p>
      <p class="en">Your information is safe, we will never share it</p>
    </div>

  </div>
</template>

<script>
import firebase from "../firebase.js";

export default {
  data() {
    return {
      name: "",
      email: "",
      myPhone: "",
      budget: "",
      query: "",
      phone: null,
      questionStringEn: "",
      questionStringAr: "",
      nameEmpty: false,
      phoneEmpty: false,
      answerEmpty: false,
      success: false,
      errorGotten: false,
    };
  },
  mounted() {
   this.getNewQuestion();
   
  },
  methods: {
    getNewQuestion(){
       var enArray = [
      "Five + Four",
      "Three + Four",
      "Six + Six",
      "Two + Three",
      "Seven + Seven",
    ];
    var arArray = [
      "أربعة + خمسة",
      "ثلاثة + اربعة",
      "ستة + ستة",
      "اثنان + ثلاثة",
      "سبعة + سبعة",
    ];

    var rand = Math.floor(Math.random() * 5);

    this.questionStringEn = enArray[rand];
    this.questionStringAr = arArray[rand];
    },
    letsGo() {
      // this.$recaptcha("doSomething").then((x) => {
      //   console.log(x);
      // });
      var error = 0;
      if (this.name.length <= 0) {
        this.nameEmpty = true;
        error++;
      } else {
        this.nameEmpty = false;
      }
      if (this.myPhone.length <= 0) {
        this.phoneEmpty = true;
        error++;
      } else {
        this.phoneEmpty = false;
      }
      if (this.phone == null || this.phone == "") {
        this.answerEmpty = true;
        error++;
      } else {
        this.answerEmpty = false;
      }
      if (error > 0) return;
      firebase
        .database()
        .ref("contacts")
        .push({
          name: this.name,
          email: this.email,
          phone: this.phone,
          budget: this.budget,
          query: this.query,
          myPhone: this.myPhone,
          createdAt: new Date().toDateString(),
        })
        .then(() => {
          this.errorGotten = false;
          this.success = true;
          this.name = "";
          this.email = "";
          this.myPhone = "";
          this.budget = "";
          this.query = "";
          this.phone = null;
          this.getNewQuestion();
        })
        .catch(() => {
          this.errorGotten = true;
          this.success = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.row {
  background-color: #181818;
  padding-bottom: 20px;
  // border-top: 2px solid #0a67a1;
  // border-bottom: 2px solid #a10a0a;
  .service-divider {
    width: 80%;
    margin-top: 20px;
    border: 0;
    border-top: 1px solid rgb(110, 110, 110);
    @media (min-width: 769px) {
      display: none;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
  .success {
    color: #13b43b;
  }
  h1 {
    color: white;
    direction: rtl;
    hr {
      border: 0;
      border-top: 2px solid white;
      width: 50%;
    }
  }
  .content-no-flex {
    max-width: 1120px;

    margin: 0 auto 0 auto;
    hr {
      margin-top: 20px;
      width: 80%;
      border: 0;
      border-top: 1px solid rgb(110, 110, 110);
    }
    p {
      color: white;
      margin: 0 0 0 0;
      font-size: 20px;
    }
    .ar {
      direction: rtl;
      text-align: center;
    }
    .en {
      direction: ltr;
      text-align: center;
    }
  }
  .content {
    max-width: 1120px;

    margin: 0 auto 0 auto;
    display: flex;
    @media (min-width: 769px) {
      flex-direction: row;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
    .flex-item {
      width: 100%;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
    }
    h2 {
      color: rgb(209, 209, 209);
      margin: 0 0 0 0;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      input,
      textarea {
        width: 60%;
        margin: 0 auto 0 auto;
        border: 1px solid #4e4e4e;

        margin-bottom: 10px;
        background-color: #313131;
        text-align: center;
        color: white;
        outline: none;
        padding: 5px;
        @media (max-width: 769px) {
          width: 90%;
        }
      }
      .important-phone {
        border: 1px solid #c01919;
      }
      .error {
        color: red;
        font-size: 12px;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
      }
      .error-input {
        border: 1px solid red;
      }
    }
  }

  * {
    font-family: "Cairo";
    font-weight: 300;
    color: #333333;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
  }
  h1 {
    margin: 0 0 0 0;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .left {
    text-align: left;
    direction: ltr;
  }
  .right {
    text-align: right;
    direction: rtl;
  }
  .small_text {
    font-size: 17px;
    text-align: justify;
  }
}
@media (min-width: 769px) {
  h1 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 10px;
  }
}
a.my_work_button_div,
.my_work_button_div {
  margin: 0 auto 0 auto;
  border: 1px solid white;
  text-decoration: none;
  margin-bottom: 5px;
  background-color: #fdca40;
  text-align: center;
  color: black;
  outline: none;
  padding: 5px;
  width: 60%;
  @media (max-width: 768px) {
    width: 90%;
  }
}

a.my_work_button_div:hover,
.my_work_button_div:hover {
  color: white;
  background-color: #000000;
}
.email-container {
  p {
    color: rgb(185, 185, 185);
    margin: 5px 0 10px 0;
  }
  a.email-button {
    margin: 5px auto 0 auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
    background-color: #c01919;
    text-align: center;
    color: white;
    outline: none;
    padding: 5px;
    width: 60%;
    @media (max-width: 768px) {
      width: 90%;
    }
    &:hover {
      background-color: white;
      color: black;
    }
  }
}
</style>
